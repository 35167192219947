export const PATH_NAMES = {
  HOME: '/',
  LOGIN: '/login',
  UPLOAD: '/upload',
  DATASETS: '/datasets',
  DATASET_CREATE: `/datasets/create`,
  DATASET_UPDATE: (id: number | false) => {
    if (id === false) return '/datasets/:id/update'
    if (isNaN(id)) return '/datasets'
    return `/datasets/${id || ':id'}/update`
  },
  DATASET_DETAIL: (id: number | false) => {
    if (id === false) return '/datasets/:id'
    if (isNaN(id)) return '/datasets'
    return `/datasets/${id || ':id'}`
  },
  ASSET_CREATE: `/assets/create`,
  ASSETS: `/assets`,
  FORGOT_PASSWORD: `/forgot-password`,
  CHANGE_PASSWORD: `/change-password`,
  RESET_PASSWORD: `/reset-password/:uid/:token`,
  TRANSLATE: (langcode: string | false) => {
    if (langcode === false) return '/translate/:langcode'
    return `/translate/${langcode}`
  },
  TRANSLATE_SELECTION: '/translate',
}

