import { getLanguageContent, getLanguages } from 'apis/languages'
import React, { createContext, useEffect, useState } from 'react'
import EN from 'locales/en.json'
import { ILanguage } from 'models/user'
import { localeStorage } from 'services/local-storage'

export const LanguageContext = createContext({} as any)

const DEFAULT_LOCALE = localeStorage.value?.currentLocale ?? 'en'
const LanguagesContainer: React.FC = (props) => {
  const [languages, setLanguages] = useState(EN)
  const [locale, setLocale] = useState(DEFAULT_LOCALE)
  const [locales, setLocales] = useState<ILanguage[]>([])

  useEffect(() => {
    getLanguages()
      .then((x) => {
        setLocales(x.data.locales)
      })
      .catch((e) => {
        console.log(e)
        setLocales([])
      })
  }, [])

  useEffect(() => {
    getLanguageContent(locale)
      .then((x) => {
        setLanguages(x.data.languages as any)
      })
      .catch((e) => {
        setLanguages(EN)
      })
  }, [locale])

  const handleSetLocale = async (locale: ILanguage['code']) => {
    localeStorage.set({ currentLocale: locale })
    setLocale(locale)
  }

  return (
    <LanguageContext.Provider
      value={{ languages, setLocale: handleSetLocale, locale, locales }}
    >
      {props.children}
    </LanguageContext.Provider>
  )
}

export { LanguagesContainer }

