import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios'
import { ENV } from 'configs'
import { HttpStatusCode } from 'models/api'
import { localeStorage } from './local-storage'

type RequestConfig = AxiosRequestConfig & {
  showLoading?: boolean
  isRefreshingToken?: boolean
  retry?: number
}

type HttpRequestCallback = {
  onShowLoading?: () => void
  onHideLoading?: () => void
  onCatchUnauthorizedError?: () => void
  onRetryUnauthorizedError?: () => Promise<void>
  onSetAuthorization?: (prevToken: string) => void
}

type ErrorResponse = AxiosError & {
  config: Pick<RequestConfig, 'showLoading' | 'isRefreshingToken' | 'retry'>
}

class HttpRequest {
  readonly instance: AxiosInstance
  private _isAttachedAuthorizationToken = false
  private _callbacks?: HttpRequestCallback

  tokens: string[] = []

  constructor(baseURL: string, config?: RequestConfig) {
    this.instance = axios.create({
      baseURL,
      timeout: 360000, // 6 min
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      ...config,
    })
  }

  private _handleError(error: AxiosError) {
    return Promise.reject({
      success: false,
      message:
        error.response?.data?.message ||
        (!error.response?.status
          ? 'Timed out, please try again'
          : error.message),
      error: error,
      code: error.response?.status,
    })
  }

  initRequest() {
    let requestCount = 0

    const decreaseRequestCount = () => {
      requestCount -= 1
      if (requestCount === 0) {
        this._callbacks?.onHideLoading && this._callbacks.onHideLoading()
      }
    }

    this.instance.interceptors.request.use(
      (config: RequestConfig) => {
        if (config.showLoading) {
          requestCount += 1
          this._callbacks?.onShowLoading && this._callbacks.onShowLoading()
        }
        const currentLocale = localeStorage.value?.currentLocale || 'en'
        config.headers['Accept-Language'] = currentLocale
        return config
      },
      (error: ErrorResponse) => {
        if (error.config.showLoading) {
          decreaseRequestCount()
        }
        return Promise.reject(error)
      },
    )

    this.instance.interceptors.response.use(
      (res: any) => {
        if (res.config.showLoading) {
          decreaseRequestCount()
        }
        return res
      },
      async (error: ErrorResponse) => {
        const originalRequest = error.config

        try {
          if (error.response?.status === HttpStatusCode.Unauthorized) {
            this.deleteAuthorization()
            if (originalRequest.isRefreshingToken) {
              // refresh token invalid -> trigger clear auth
              this._callbacks?.onCatchUnauthorizedError &&
                this._callbacks.onCatchUnauthorizedError()
            } else if (this._callbacks?.onRetryUnauthorizedError) {
              // trigger func renew access token
              await this._callbacks.onRetryUnauthorizedError()
              // retry api call
              if (
                originalRequest.retry !== undefined &&
                originalRequest.retry > 0
              ) {
                originalRequest.showLoading && decreaseRequestCount()
                originalRequest.retry -= 1
                // override original headers with new headers
                originalRequest.headers = this.instance.defaults.headers.common
                return await this.instance(originalRequest)
              }

              if (!originalRequest.retry === undefined) {
                throw new Error('Something went wrong. Please try again!')
              }
            }
          }
          originalRequest.showLoading && decreaseRequestCount()
        } catch (internalError) {
          originalRequest.showLoading && decreaseRequestCount()
          return Promise.reject(internalError)
        }

        return Promise.reject(error)
      },
    )
  }

  injectCallbacks(cb: HttpRequestCallback) {
    this._callbacks = cb
  }

  setAuthorization(token: string, type: 'Token' | 'Bearer') {
    this._isAttachedAuthorizationToken = true
    this.instance.defaults.headers.common['Authorization'] = `${type} ${token}`
    this.tokens.push(token)
    this._callbacks?.onSetAuthorization &&
      this._callbacks.onSetAuthorization(token)
  }

  deleteAuthorization() {
    if (this._isAttachedAuthorizationToken) {
      delete this.instance.defaults.headers.common['Authorization']
      this._isAttachedAuthorizationToken = false
    }
  }

  get(path: string, config?: RequestConfig) {
    return this.instance.get(path, config).catch(this._handleError)
  }

  post(path: string, data: any, config?: RequestConfig) {
    return this.instance.post(path, data, config).catch(this._handleError)
  }

  put(path: string, data: any, config?: RequestConfig) {
    return this.instance.put(path, data, config).catch(this._handleError)
  }

  patch(path: string, data: any, config?: RequestConfig) {
    return this.instance.put(path, data, config).catch(this._handleError)
  }

  delete(path: string, config?: RequestConfig) {
    return this.instance.delete(path, config).catch(this._handleError)
  }
}

export const httpRequest = new HttpRequest(ENV.API_ENDPOINT, {
  showLoading: false,
})

export const commonDBHttpRequest = new HttpRequest(ENV.COMMON_DB_API_ENDPOINT, {
  showLoading: false,
})

export const oneMapHttpRequest = new HttpRequest(ENV.ONEMAP_URL, {
  showLoading: false,
})

export const commonDBV2HttpRequest = new HttpRequest(
  ENV.COMMON_DB_V2_API_ENDPOINT,
  {
    showLoading: false,
  },
)

