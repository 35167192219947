import { Modal } from 'components/Modal'
import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Prompt } from 'react-router-dom'
import { RootState } from 'store/rootReducer'
import { useDispatch } from 'react-redux'
import { updateStatus, visibleConfirmByControl } from 'store/slices/leavePage'
type ConfirmLeavePageProps = {
  message: string
  visible: boolean
}

const ConfirmLeavePage: React.FC<ConfirmLeavePageProps> = ({
  message,
  visible,
}) => {
  // refresh / close tab
  const dispatch = useDispatch()
  const leavePageByFunction = useSelector(
    (state: RootState) => state.confirmLeavePage.leavePageByFunction,
  )
  const visibleConfirm = useSelector(
    (state: RootState) => state.confirmLeavePage.visibleConfirm,
  )

  useEffect(() => {
    dispatch(updateStatus({ visible: visible }))
    return () => {
      dispatch(visibleConfirmByControl())
      dispatch(updateStatus({ visible: false }))
    }
  }, [visible, dispatch])

  useEffect(() => {
    const handleCloseRefresh = (e: BeforeUnloadEvent) => {
      if (visibleConfirm) {
        e.preventDefault()
        e.returnValue = ''
        return ''
      }
    }
    window.addEventListener('beforeunload', handleCloseRefresh)
    return () => {
      window.removeEventListener('beforeunload', handleCloseRefresh)
    }
  }, [visibleConfirm])

  return (
    <>
      {!leavePageByFunction && (
        <Prompt when={visibleConfirm} message={message} />
      )}
    </>
  )
}

export const confirmBox = (
  message: string,
  visible: boolean,
  handleOnOk: () => void,
  handleOnCancel: () => void,
  metadata?: {
    okText?: string;
    cancelText?: string;
  }
) => {
  if (visible) {
    Modal.confirm({
      message: message,
      okText: metadata?.okText || 'Yes',
      cancelText: metadata?.cancelText || 'No',
      okType: 'danger',
      closeOnClickOutside: true,
      closeOnEscape: true,
      icon: undefined,
      onOk: () => {
        return handleOnOk()
      },
      onCancel: () => {
        return handleOnCancel()
      },
    })
  } else {
    return handleOnOk()
  }
}

export default memo(ConfirmLeavePage)
