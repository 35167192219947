import { BrowserRouter as Router } from 'react-router-dom'
import './App.scss'
import '@fortawesome/fontawesome-free/css/all.css'

import Routes from 'routes'
import { Header } from 'layout/Header'
import { GlobalContainer } from 'containers/Global'
import { NotificationContainer } from 'containers/Notification'
import { ErrorBoundary } from 'containers/ErrorBoundary'
import { LanguagesContainer } from 'containers/Languages'

function App() {
  return (
    <div className="App d-flex flex-column">
      <NotificationContainer>
        <LanguagesContainer>
          <GlobalContainer>
            <Router>
              <Header />
              <ErrorBoundary>
                <Routes />
              </ErrorBoundary>
            </Router>
          </GlobalContainer>
        </LanguagesContainer>
      </NotificationContainer>
    </div>
  )
}

export default App
