import { ENV } from 'configs'
import { Storage } from 'models/storage'
import { Account } from 'models/user'
import { LocalStorageUtil } from 'utils/local-storage'

export class LocalStorage<T> extends Storage<T> {
  get config(): { version: number } {
    const value = LocalStorageUtil.getJSON(this.key) as any
    return {
      version: value?.version,
    }
  }
  get value(): T | null {
    if (this.type === 'json') {
      return LocalStorageUtil.getJSON(this.key)
    } else {
      return LocalStorageUtil.get(this.key) as any
    }
  }

  constructor(
    key: string,
    type: 'json' | 'string',
    sensitive: boolean,
    version: number,
  ) {
    super(key, type, sensitive, version)
    this.checkOutdated()
  }

  set(value: T) {
    LocalStorageUtil.set(this.key, { ...value, version: this.version })
  }

  update(values: Partial<T>) {
    if (this.type === 'json') {
      this.set({ ...this.value, ...values } as T)
    } else {
      LocalStorageUtil.set(this.key, values)
    }
  }

  clear() {
    LocalStorageUtil.clear(this.key)
  }

  checkOutdated() {
    if (this.version !== this.config.version) {
      this.clear()
    }
  }
}

// curr version: 1
export const accountStorage = new LocalStorage<Account>(
  ENV.LOCAL_KEY,
  'json',
  true,
  1,
)

export const localeStorage = new LocalStorage<{ currentLocale: string }>(
  `${ENV.LOCAL_KEY}-locale`,
  'json',
  true,
  1,
)

