import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ENV } from 'configs'
import { Client, SubscriptionApp, User } from 'models/user'

interface AuthState {
  init: boolean
  isAuthenticated: boolean
  isExpired: boolean
  user?: User
  subscribedApps?: SubscriptionApp[]
  client?: Client
  enableFlightMapApp?: boolean,
  isAccessTransPage?: boolean
}

const initialState: AuthState = {
  isAuthenticated: false,
  init: true,
  isExpired: false,
  isAccessTransPage: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authen(
      state,
      action: PayloadAction<
        Pick<Required<AuthState>, 'client' | 'user' | 'subscribedApps' | 'isAccessTransPage'>
      >,
    ) {
      const { payload } = action
      state.init = false
      state.isAuthenticated = true
      state.client = payload.client
      state.user = payload.user
      state.subscribedApps = payload.subscribedApps
      state.isExpired = false
      state.enableFlightMapApp = ENV.FLIGHT_APP_USER.includes(
        payload.user.username,
      )
      state.isAccessTransPage = payload.isAccessTransPage
    },
    authWithFailed(state) {
      state.init = false
    },
    setSubscribedApps(state, action: PayloadAction<SubscriptionApp[]>) {
      state.subscribedApps = action.payload
    },
    expireSession(state) {
      if (state.isAuthenticated) {
        state.isExpired = true
      }
    },
    clear() {
      return { ...initialState, init: false }
    },
  },
})

export const { actions } = authSlice

export default authSlice.reducer
