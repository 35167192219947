import React, { lazy, Suspense, useContext } from 'react'
import { RouteComponentProps, RouteProps, Switch } from 'react-router-dom'

import { Loading } from 'components/Loading'
import { HeaderContent } from 'models/app'
import { PATH_NAMES } from 'configs/path-names'

import { ProtectRoute } from './ProtectRoute'
import { PublicRoute } from './PublicRoute'
import { LanguageContext } from 'containers/Languages'
import { get } from 'lodash'

export interface RouteConfig extends Pick<RouteProps, 'path' | 'exact'> {
  component: React.FC<RouteProps & RouteConfig>
  header: HeaderContent
  roles?: string[]
  redirect?: string
  disabled?: boolean
  route?: React.FC<RouteConfig>
  autoScrollTop?: boolean
  onBeforeRender?(
    routeProps: RouteConfig,
    renderProps: RouteComponentProps<any, any, any>,
  ): void
}

const Routes = () => {
  const { languages } = useContext(LanguageContext);

  const routes: RouteConfig[] = [
    {
      component: lazy(() => import('pages/Home')),
      path: PATH_NAMES.HOME,
      exact: true,
      route: ProtectRoute,
      header: {
        title: get(languages, ["HOME", "title"]),
      },
    },
    {
      component: lazy(() => import('pages/Login')),
      path: PATH_NAMES.LOGIN,
      header: {
        title: get(languages, ["SIGNIN", "title"]),
        subTitle: 'login',
      },
    },
    {
      component: lazy(() => import('pages/Upload')),
      path: PATH_NAMES.UPLOAD,
      route: ProtectRoute,
      header: {
        title: get(languages, ["UPLOAD", "titleUploadDataset"]),
      },
      autoScrollTop: true,
    },
    {
      component: lazy(() => import('pages/DatasetCreate')),
      path: PATH_NAMES.DATASET_CREATE,
      route: ProtectRoute,
      header: {
        title: get(languages, ["DATASET_CREATE", "title"]),
      },
      onBeforeRender: (_, renderProps) => {
        if (
          !(renderProps.location.state?.flow && renderProps.location.state?.app)
        ) {
          renderProps.history.push(PATH_NAMES.HOME)
        }
      },
      autoScrollTop: true,
    },
    {
      component: lazy(() => import('pages/DatasetUpdate')),
      path: PATH_NAMES.DATASET_UPDATE(false),
      route: ProtectRoute,
      header: {
        title: get(languages, ["DATASET_UPDATE", "title"]),
      },
      autoScrollTop: true,
    },
    {
      component: lazy(() => import('pages/DatasetList')),
      path: PATH_NAMES.DATASETS,
      exact: true,
      route: ProtectRoute,
      header: {
        title:  get(languages, ["DATASET", "buttonDatasets"]),
      },
      autoScrollTop: true,
    },
    {
      component: lazy(() => import('pages/DatasetDetail')),
      path: PATH_NAMES.DATASET_DETAIL(false),
      route: ProtectRoute,
      exact: true,
      header: {
        title: get(languages, ["DATASET_DETAIL", "title"]),
      },
      autoScrollTop: true,
    },
    // -- asset routes
    {
      component: lazy(() => import('pages/AssetCreate')),
      path: PATH_NAMES.ASSET_CREATE,
      route: ProtectRoute,
      header: {
        title: get(languages, ["ASSET_CREATE", "titleCreateNew"]),
      },
      autoScrollTop: true,
    },
    {
      component: lazy(() => import('pages/AssetList')),
      path: PATH_NAMES.ASSETS,
      route: ProtectRoute,
      exact: true,
      header: {
        title: get(languages, ["ASSET", "title"]),
      },
      autoScrollTop: true,
    },
    {
      component: lazy(() => import('pages/ForgotPassword')),
      path: PATH_NAMES.FORGOT_PASSWORD,
      header: {
        title: get(languages, ["FORGOT_PASSWORD", "title"]),
      },
    },
    {
      component: lazy(() => import('pages/ChangePassword')),
      path: PATH_NAMES.CHANGE_PASSWORD,
      route: ProtectRoute,
      header: {
        title: get(languages, ["RESET_PASSWORD", "titleChangePwd"]),
      },
    },
    {
      component: lazy(() => import('pages/ResetPassword')),
      path: PATH_NAMES.RESET_PASSWORD,
      header: {
        title: get(languages, ["FORGOT_PASSWORD", "buttonResetPassword"]),
      },
    },
    {
      component: lazy(() => import("pages/TranslateSelection")),
      path: PATH_NAMES.TRANSLATE_SELECTION,
      exact: true,
      route: ProtectRoute,
      header: {
        title: get(languages, ["TRANSLATE_PAGE", "title"])
      }
    },
    {
      component: lazy(() => import("pages/TranslationPage")),
      path: PATH_NAMES.TRANSLATE(false),
      exact: true,
      route: ProtectRoute,
      header: {
        title: get(languages, ["TRANSLATE_PAGE", "title"])
      }
    },
    {
      component: lazy(() => import('pages/NotFound')),
      path: '*',
      header: {
        title: get(languages, ["HELP_TEXT", "helpTextPageNotFound"]),
      },
    },
  ]

  return (
    <Suspense fallback={<Loading className="flex-grow-1" loading />}>
      <Switch>
        {routes.map((item, index) => {
          const { disabled, route: CustomRoute } = item
          if (disabled) return null
          if (CustomRoute) {
            return <CustomRoute {...item} key={index} />
          }
          return <PublicRoute {...item} key={index} />
        })}
      </Switch>
    </Suspense>
  )
}

export default Routes
