import { DataResponse, RequestResponse } from 'models/api'
import { ILanguage } from 'models/user'
import { httpRequest } from 'services/http-request'

export const getLanguages = async (
  // clientId: number,
): Promise<DataResponse<{ locales: ILanguage[] }>> => {
  try {
    const res: RequestResponse<ILanguage[]> = await httpRequest.get(
      '/languages',
      {
        showLoading: true,
        retry: 1,
        params: {
          // client_id: clientId,
        },
      },
    )

    if (!res.data.success) {
      throw new Error(res.data.message)
    }

    return {
      status: true,
      data: { locales: res.data.data.sort((a, b) => a.id - b.id) },
      message: res.data.message,
    }
  } catch (error: any) {
    return Promise.reject({
      status: false,
      message: error.message,
    })
  }
}

export const getLanguageContent = async (
  locale: string,
): Promise<DataResponse<{ languages: any[] }>> => {
  try {
    const res: RequestResponse<any[]> = await httpRequest.get(
      '/language-content',
      {
        showLoading: true,
        retry: 1,
        params: {
          // client_id: clientId,
        },
        headers: {
          "Accept-Language": locale
        }
      },
    )

    if (!res.data.success) {
      throw new Error(res.data.message)
    }

    return {
      status: true,
      data: { languages: res.data.data },
      message: res.data.message,
    }
  } catch (error: any) {
    return Promise.reject({
      status: false,
      message: error.message,
    })
  }
}

export const LanguageAPI = {
  getLanguages,
  getLanguageContent
}
