import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions as authActions } from 'store/slices/auth'
import { refreshTokenStorage, tokenStorage } from 'services/cookie'
import { UserAPI } from 'apis/user'
import { RootState } from 'store/rootReducer'
import { httpRequest } from 'services/http-request'
import { AuthAPI } from 'apis/auth'
import { useToaster } from 'containers/Notification'
import { saveToken } from 'utils/token'
import { LanguageContext } from 'containers/Languages'

export const GlobalContainer: React.FC = (props) => {
  const dispatch = useDispatch()
  const toast = useToaster()
  const { locale } = useContext(LanguageContext)
  const client = useSelector((state: RootState) => state.auth.client)
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  )
  const isExpired = useSelector((state: RootState) => state.auth.isExpired)

  // const prevSubscriptionApps = useSelector(
  //   (state: RootState) => state.auth.subscribedApps,
  // )

  const initAuth = async () => {
    try {
      let accessToken = tokenStorage.value?.accessToken
      const refreshToken = refreshTokenStorage.value?.refreshToken

      if (!accessToken && !refreshToken) {
        throw new Error('Cached auth not found')
      }
      if (!accessToken && refreshToken) {
        try {
          const res = await AuthAPI.refreshToken(refreshToken)
          const newToken = res.data.access_token
          if (!newToken) throw new Error('token renew invalid!')
          accessToken = newToken
        } catch (error: any) {
          throw new Error(error.message)
        }
      }
      if (!accessToken) throw new Error('Token not found')

      httpRequest.setAuthorization(accessToken, 'Bearer')
      const res = await AuthAPI.getUserProfile()
      const auth = {
        user: res.data.user,
        subscribedApps: res.data.applications.sort((a, b) => a.id - b.id),
        client: res.data.client,
        refreshToken: res.data.refresh_token,
        isAccessTransPage: res.data.is_access_trans_page
      }
      saveToken({ accessToken: accessToken })
      dispatch(authActions.authen(auth))
    } catch (error) {
      console.error(error)
      dispatch(authActions.authWithFailed())
    }
  }

  useEffect(() => {
    if (client) {
      UserAPI.getSubscribedApp(client.id)
        .then((res) => {
          dispatch(authActions.setSubscribedApps(res.data.apps))
        })
        .catch((error) => {
          console.error(error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, locale])

  useEffect(() => {
    if (!isAuthenticated) {
      httpRequest.deleteAuthorization()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (isExpired) {
      toast.error('Your session has expired. Please sign in again!')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpired])

  useEffect(() => {
    initAuth()
    // TODO: Get user info to avoid data outdated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <> {props.children} </>
}

